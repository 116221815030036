<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span v-if="vendor && product" class="text-truncate">
          Vendor{{ " " + vendor + ": " }}Product {{ " " + product + ": " }}Security Vulnerabilities
        </span>
        <span v-else-if="vendor" class="text-truncate"> Vendor{{ " " + vendor + ": " }}Security Vulnerabilities </span>
        <span v-else-if="product" class="text-truncate">
          Product {{ " " + product + ": " }}Security Vulnerabilities
        </span>
        <span v-else-if="year" class="text-truncate">
          Security Vulnerabilities Published In {{ year + "-" + month }}
        </span>
        <span v-else class="text-truncate">
          Security Vulnerabilities Published In
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3" dense>
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              dense
              fixed-header
              locale="en"
              hide-default-footer
              show-expand
              item-key="cve_ID"
              :expanded.sync="expanded"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :server-items-length="totalResults"
              :search="search"
              :headers="headers"
              :items="vulnerabilities"
              class="elevation-1"
            >
              <template #item.cve_ID="{ item }">
                <router-link :to="'/cve/' + item.cve_ID">
                  {{ item.cve_ID }}
                </router-link>
              </template>
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0">
                  <v-sheet class="pa-2 mt-n1">
                    <div class="text-body-1">
                      {{ item.description }}
                    </div>
                  </v-sheet>
                  <!-- {{ item.description }} -->
                </td>
              </template>
              <template #body.prepend="{ headers }">
                <tr v-if="!$vuetify.breakpoint.xs" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
                  <td v-for="header in headers" :key="header.value" class="px-1">
                    <div
                      v-if="
                        header.value != 'product_type' &&
                          header.value != 'vce_entries' &&
                          header.value != 'data-table-expand'
                      "
                      style="width:100%"
                      class="d-inline-flex"
                    >
                      <span v-show="false">{{ render }}</span>
                      <v-select
                        v-if="header.value == 'gained'"
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        append-icon=""
                        clearable
                        dense
                        hide-details
                        :items="['NONE', 'ADMIN', 'USER', 'OTHER']"
                        @change="
                          page = 1
                          getRecords()
                        "
                      />
                      <v-select
                        v-else-if="header.value == 'access'"
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        append-icon=""
                        clearable
                        dense
                        hide-details
                        :items="['NETWORK', 'ADJACENT_NETWORK', 'LOCAL']"
                        @change="
                          page = 1
                          getRecords()
                        "
                      />
                      <v-select
                        v-else-if="header.value == 'complexity'"
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        append-icon=""
                        clearable
                        dense
                        hide-details
                        :items="['HIGH', 'MEDIUM', 'LOW']"
                        @change="
                          page = 1
                          getRecords()
                        "
                      />
                      <v-select
                        v-else-if="header.value == 'authentication'"
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        append-icon=""
                        clearable
                        dense
                        hide-details
                        :items="['MULTIPLE', 'SINGLE', 'NONE']"
                        @change="
                          page = 1
                          getRecords()
                        "
                      />
                      <v-select
                        v-else-if="
                          header.value == 'confidentiality' ||
                            header.value == 'integrity' ||
                            header.value == 'availability'
                        "
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        append-icon=""
                        clearable
                        dense
                        hide-details
                        :items="['NONE', 'PARTIAL', 'COMPLETE']"
                        @change="
                          page = 1
                          getRecords()
                        "
                      />
                      <v-text-field
                        v-else
                        v-model="filtersTM[header.value]"
                        style="width:100%"
                        class="mb-1"
                        clearable
                        dense
                        hide-details
                        @keydown.enter="
                          page = 1
                          getRecords()
                        "
                        @click:clear="
                          filtersTM[header.value] = ''
                          page = 1
                          getRecords()
                        "
                      />
                      <v-btn
                        class="mr-0 mt-1"
                        icon
                        small
                        @click="
                          page = 1
                          getRecords()
                        "
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                    <!-- {{ header.value }} -->
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="mt-2 flex-wrap" cols="12">
            <div style="width:200px" class="float-sm-left d-flex mb-2">
              <v-select
                v-model="itemsPerPage"
                style="width:85px"
                class="px-2 py-0 my-0 "
                :items="rowsPerPageItems"
                flat
                hide-details
              />
              <span class="mt-2  text-body-1">
                {{ (page - 1) * itemsPerPage + 1 }}-{{
                  totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
                }}
                {{ "of" }} {{ totalResults }}
              </span>
            </div>
            <v-pagination
              v-model="page"
              class="d-flex"
              :length="Math.ceil(totalResults / itemsPerPage)"
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      path: "",
      vulnerabilities: [],
      loading: true,
      vendor: "",
      language: "",
      update: "",
      edition: "",
      render: 1,
      year: 2021,
      month: 1,
      search: "",
      page: 1,
      expanded: [],
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ],
      filtersTM: {
        cve_ID: "",
        cwe_ID: "",
        vce_entries: "",
        product_type: "",
        publishedDate: "",
        lastModifiedDate: "",
        score: "",
        gained: "",
        access: "",
        complexity: "",
        authentication: "",
        confidentiality: "",
        integrity: "",
        availability: ""
      },
      filtresFields: {
        cve_ID: "cve.CVE_data_meta.ID",
        cwe_ID: "cve.problemtype.problemtype_data.0.description.0.value",
        vce_entries: "",
        product_type: "",
        publishedDate: "publishedDate",
        lastModifiedDate: "lastModifiedDate",
        score: "impact.baseMetricV2.cvssV2.baseScore",
        gained: "gained",
        access: "impact.baseMetricV2.cvssV2.accessVector",
        complexity: "impact.baseMetricV2.cvssV2.accessComplexity",
        authentication: "impact.baseMetricV2.cvssV2.authentication",
        confidentiality: "impact.baseMetricV2.cvssV2.confidentialityImpact",
        integrity: "impact.baseMetricV2.cvssV2.integrityImpact",
        availability: "impact.baseMetricV2.cvssV2.availabilityImpact"
      },
      headers: [
        { text: "CVE ID", value: "cve_ID", width: "150px" },
        { text: "CWE ID", value: "cwe_ID", width: "140px" },
        { text: "# of Exploits", value: "vce_entries" },
        { text: "Vulnerability Type(s)", value: "product_type" },
        { text: "Publish Date", value: "publishedDate", width: "110px" },
        { text: "Update Date", value: "lastModifiedDate", width: "110px" },
        { text: "Score", value: "score" },
        { text: "Gained Access Level", value: "gained" },
        { text: "Access", value: "access" },
        { text: "Complexity", value: "complexity" },
        { text: "Authentication", value: "authentication" },
        { text: "Conf.", value: "confidentiality" },
        { text: "Integ.", value: "integrity" },
        { text: "Avail.", value: "availability" }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    },
    page() {
      this.getRecords()
    }
  },
  created() {
    //this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      this.product = ""
      this.vendor = ""
      this.language = ""
      this.update = ""
      this.edition = ""
      this.month = ""
      this.year = ""
      if (this.$route.name == "VulnerabilitiesVendorEdition") {
        this.$log("rrr edition", this.$route)
        this.loading = true
        this.product = this.$route.params["1"]
        this.language = this.$route.params["2"] != "*" ? this.$route.params["2"] : ""
        this.update = this.$route.params["3"] != "*" ? this.$route.params["3"] : ""
        this.edition = this.$route.params["4"] != "*" ? this.$route.params["4"] : ""
        this.vendor = this.$route.params.pathMatch
        this.getRecords()
      } else if (this.$route.name == "VulnerabilitiesVendorProduct") {
        this.$log("rrr ", this.$route)
        this.loading = true
        this.product = this.$route.params["1"]
        this.vendor = this.$route.params.pathMatch
        this.getRecords()
      } else if (this.$route.name == "VulnerabilitiesVendor") {
        this.loading = true
        this.vendor = this.$route.params.pathMatch
        this.getRecords()
      } else if (this.$route.name == "VulnerabilitiesProduct") {
        this.loading = true
        this.product = this.$route.params.pathMatch
        this.getRecords()
      } else if (this.$route.name == "VulnerabilitiesMonth") {
        this.loading = true
        this.year = this.$route.params.pathMatch
        this.month = this.$route.params["1"]
        this.getRecords()
      } else if (this.$route.name == "VulnerabilitiesSearch") {
        this.loading = true
        this.getRecords()
      }
    },
    getRecords() {
      //this.$log("filtres ", this.filtersTM)
      let where = ""
      //where={"cve.CVE_data_meta.ID":{"$regex":"CVE%5C%5C-1999%5C%5C-0002","$options":"i"}}
      for (const filter in this.filtersTM) {
        if (this.filtersTM[filter] != "" && this.filtersTM[filter] != null) {
          if (filter == "score") {
            where += ',"' + this.filtresFields[filter] + '":' + encodeURIComponent(this.filtersTM[filter])
          } else if (filter == "gained") {
            if (this.filtersTM[filter] == "ADMIN") {
              where += ',"impact.baseMetricV2.obtainAllPrivilege":true'
            } else if (this.filtersTM[filter] == "USER") {
              where += ',"impact.baseMetricV2.obtainUserPrivilege":true'
            } else if (this.filtersTM[filter] == "OTHER") {
              where += ',"impact.baseMetricV2.obtainOtherPrivilege":true'
            } else if (this.filtersTM[filter] == "NONE") {
              where += ',"impact.baseMetricV2.obtainAllPrivilege":false'
              where += ',"impact.baseMetricV2.obtainUserPrivilege":false'
              where += ',"impact.baseMetricV2.obtainOtherPrivilege":false'
            }
          } else {
            where +=
              ',"' +
              this.filtresFields[filter] +
              '":{"$regex":"' +
              encodeURIComponent(this.filtersTM[filter]) +
              '","$options":"i"}'
          }
        }
      }
      this.$log("product ", this.product)
      this.$log("vendor ", this.vendor)
      if (this.product) {
        where += ',"configurations.nodes.cpe_match.cpe23List.product":"' + encodeURIComponent(this.product) + '"'
      }
      if (this.vendor) {
        where += ',"configurations.nodes.cpe_match.cpe23List.vendor":"' + encodeURIComponent(this.vendor) + '"'
      }
      if (this.language) {
        where +=
          ',"configurations.nodes.cpe_match.cpe23List.language":{"$regex":"' +
          encodeURIComponent(this.language) +
          '","$options":"i"}'
      }
      if (this.edition) {
        where +=
          ',"configurations.nodes.cpe_match.cpe23List.edition":{"$regex":"' +
          encodeURIComponent(this.edition) +
          '","$options":"i"}'
      }
      if (this.update) {
        where +=
          ',"configurations.nodes.cpe_match.cpe23List.update":{"$regex":"' +
          encodeURIComponent(this.update) +
          '","$options":"i"}'
      }
      if (this.year) {
        where += ',"publishedDate":{"$regex":"' + encodeURIComponent(this.year + "-" + this.month) + '","$options":"i"}'
      }
      if (where != "") {
        where = "&where={" + where.substr(1) + "}"
      }
      this.vulnerabilities = []
      axios
        .get("nvd_cve" + "?max_results=" + this.itemsPerPage + "&page=" + this.page + where)
        .then(response => {
          //this.$log("vulnerability ", response)
          this.totalResults = response.data._meta.total
          response.data._items.map(itm => {
            let gained = "NONE"
            if (this.resolvePath("impact.baseMetricV2.obtainAllPrivilege", itm)) {
              gained = "ADMIN"
            } else if (this.resolvePath("impact.baseMetricV2.obtainUserPrivilege", itm)) {
              gained = "USER"
            } else if (this.resolvePath("impact.baseMetricV2.obtainOtherPrivilege", itm)) {
              gained = "OTHER"
            }

            const rec = {
              cve_ID: this.resolvePath("cve.CVE_data_meta.ID", itm),
              cwe_ID: this.resolvePath("cve.problemtype.problemtype_data.0.description.0.value", itm),
              description: this.resolvePath("cve.description.description_data.0.value", itm),
              //vce_entries: this.resolvePath("impact.baseMetricV2.cvssV2.authentication", itm),
              //product_type: this.resolvePath("impact.baseMetricV2.cvssV2.authentication", itm),
              publishedDate: itm.publishedDate.split("T")[0],
              lastModifiedDate: itm.lastModifiedDate.split("T")[0],
              score: this.resolvePath("impact.baseMetricV2.cvssV2.baseScore", itm),
              gained,
              access: this.resolvePath("impact.baseMetricV2.cvssV2.accessVector", itm),
              complexity: this.resolvePath("impact.baseMetricV2.cvssV2.accessComplexity", itm),
              authentication: this.resolvePath("impact.baseMetricV2.cvssV2.authentication", itm),
              confidentiality: this.resolvePath("impact.baseMetricV2.cvssV2.confidentialityImpact", itm),
              integrity: this.resolvePath("impact.baseMetricV2.cvssV2.integrityImpact", itm),
              availability: this.resolvePath("impact.baseMetricV2.cvssV2.availabilityImpact", itm)
            }
            //itm.publishedDate = itm.publishedDate.split("T")[0]
            //itm.lastModifiedDate = itm.lastModifiedDate.split("T")[0]
            this.vulnerabilities.push(rec)
          })
          //this.vulnerabilities = response.data
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
